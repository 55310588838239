import React, { useState } from "react"
import Layout from "../components/Layout"
import type { GuestGroupResponse } from "../../functions/api/rsvp/types"
import { LoginScreen } from "../screens/rsvp/LoginScreen"
import { RSVPScreen } from "../screens/rsvp/RSVPScreen"
import { ThankYouScreen } from "../screens/rsvp/ThankYouScreen"

export interface Submission {
  hasSubmitted: boolean
  isAttending?: boolean
}

export default function RSVPPage() {
  const [group, setGroup] = useState<GuestGroupResponse | null>(null)
  const [{ hasSubmitted, isAttending }, setHasSubmitted] = useState<Submission>({ hasSubmitted: false })

  return (
    <Layout title="RSVP">
      {!group ? (
        <LoginScreen key="login" setGroup={setGroup} />
      ) : !hasSubmitted ? (
        <RSVPScreen
          key="rsvp"
          group={group}
          setHasSubmitted={setHasSubmitted}
        />
      ) : (
        <ThankYouScreen key="thankYou" isAttending={isAttending} />
      )}
    </Layout>
  )
}
