import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  ExistingRSVPResponse,
  GuestGroupResponse,
} from "../../../functions/api/rsvp/types"
import { Button } from "../../components/Button/Button"
import { Form } from "../../components/Form/Form"
import { Input } from "../../components/Input/Input"
import { Label } from "../../components/Label/Label"
import Paragraph from "../../components/Paragraph"
import { formatDate } from "../../util/formatDate"

export const LoginScreen = ({
  setGroup,
}: {
  setGroup: React.Dispatch<React.SetStateAction<GuestGroupResponse | null>>
}) => {
  const id = useMemo(() => {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(location.search)
      return searchParams.get("id")
    }
  }, [])
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const handleSubmit = useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault()
      if ((firstName && lastName) || id) {
        try {
          const url = new URL("/api/rsvp/guest", window.location.origin)

          if (id) {
            url.searchParams.set("id", id)
          } else {
            url.searchParams.set("firstName", firstName.trim())
            url.searchParams.set("lastName", lastName.trim())
          }

          const req = await fetch(url.href)

          if (req.ok) {
            const response: ExistingRSVPResponse | GuestGroupResponse =
              await req.json()

            if (Array.isArray(response)) {
              setGroup(response)
            } else {
              throw new Error(
                `It looks like an RSVP was already submitted for you on ${formatDate(
                  response.responseDate,
                )}. If you need to change your RSVP, please contact us directly.`,
              )
            }
          } else {
            throw new Error(
              "Oh no! It looks like that name is not in our guest list. Please check your invitation for a QR code.",
            )
          }
        } catch (err) {
          if (err instanceof Error) {
            setErrorMessage(err.message)
          }
        }
      }
    },
    [firstName, lastName, setGroup, id],
  )

  useEffect(() => {
    if (id) {
      handleSubmit()
    }
  }, [handleSubmit, id])

  if (id) {
    return (
      <>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <Paragraph>Loading your RSVP...</Paragraph>
      </>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <Paragraph><strong>Please submit your RSVP by May 10th</strong></Paragraph>
      <Paragraph style={{ fontSize: '1rem' }}>If you're unsure about which name to use, the QR code on the back of the details card in your invitation will fill it in automatically.</Paragraph>
      <Label>
        First Name
        <Input
          type="text"
          value={firstName}
          required
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Label>

      <Label>
        Last Name{" "}
        <Input
          type="text"
          value={lastName}
          required
          onChange={(e) => setLastName(e.target.value)}
        />
      </Label>

      <Button>Next</Button>
    </Form>
  )
}
