import React, { useCallback, useState } from "react"
import {
  GuestGroupResponse,
  GuestId,
  RSVP,
  RSVPResponse,
} from "../../../functions/api/rsvp/types"
import { Button } from "../../components/Button/Button"
import { Form } from "../../components/Form/Form"
import type { Submission } from "../../pages/rsvp"
import { GuestForm } from "../../components/GuestForm/GuestForm"
import { Input } from "../../components/Input/Input"
import { Label } from "../../components/Label/Label"
import { TextArea } from "../../components/TextArea/TextArea"
import { without } from "../../util/without"

export const RSVPScreen = ({
  group,
  setHasSubmitted,
}: {
  group: GuestGroupResponse
  setHasSubmitted: React.Dispatch<React.SetStateAction<Submission>>
}) => {
  const [attending, setAttending] = useState<Record<string, boolean>>({})
  const [mealChoices, setMealChoices] = useState<
    Record<GuestId, RSVP["mealChoice"]>
  >({})
  const [additionalGuests, setAdditionalGuests] = useState<
    Record<GuestId, string[]>
  >({})
  const [firstNames, setFirstNames] = useState<Record<GuestId, string>>({})
  const [lastNames, setLastNames] = useState<Record<GuestId, string>>({})
  const [allergies, setAllergies] = useState<Record<GuestId, string>>({})
  const [enableLeaveMessage, setEnableLeaveMessage] = useState(false)
  const [message, setMessage] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const id = group.find((guest) => guest.responder)?.id
      if (id) {
        const rsvpResponse: RSVPResponse = {
          rsvps: group.flatMap((g) => {
            return [
              {
                id: g.id,
                attending: attending[g.id],
                mealChoice: mealChoices[g.id],
                allergies: allergies[g.id],
              },
              ...(additionalGuests[g.id] || []).map((id) => ({
                id,
                attending: attending[id],
                mealChoice: mealChoices[id],
                firstName: firstNames[id],
                lastName: lastNames[id],
                allergies: allergies[id],
              })),
            ]
          }),
          message,
        }

        const req = await fetch(`/api/rsvp/guest?id=${id}`, {
          method: "POST",
          body: JSON.stringify(rsvpResponse),
        })

        if (req.ok) {
          const isAttending = group.some((g) => attending[g.id])
          setHasSubmitted({ hasSubmitted: true, isAttending })
        } else {
          setErrorMessage("Oh no! It looks like something went wrong.")
        }
      } else {
        setErrorMessage(`I couldn't figure out who the main responder is.`)
      }
    },
    [
      group,
      attending,
      mealChoices,
      setErrorMessage,
      setHasSubmitted,
      allergies,
      message,
      firstNames,
      lastNames,
    ],
  )

  const handleAddGuest = useCallback(
    (guestId: string) => {
      const guestIdGuests = additionalGuests[guestId] ?? []
      const nextGuestNumber = guestIdGuests.length
        ? Number(guestIdGuests[guestIdGuests.length - 1].split(".")[1]) + 1
        : 1
      const additionalGuestId = `${guestId}.${nextGuestNumber}`
      setAdditionalGuests({
        ...additionalGuests,
        [guestId]: [...guestIdGuests, additionalGuestId],
      })
      setAttending({ ...attending, [additionalGuestId]: true })
    },
    [setAttending, attending, additionalGuests, setAdditionalGuests, group],
  )

  const handleRemoveGuest = useCallback(
    (additionalGuestId: string) => {
      const [guestId] = additionalGuestId.split(".")
      setAdditionalGuests({
        ...additionalGuests,
        [guestId]: additionalGuests[guestId]?.filter(
          (_) => _ !== additionalGuestId,
        ),
      })

      setFirstNames(without(firstNames, additionalGuestId))
      setLastNames(without(lastNames, additionalGuestId))
      setAttending(without(attending, additionalGuestId))
    },
    [
      setAttending,
      attending,
      additionalGuests,
      setAdditionalGuests,
      firstNames,
      lastNames,
      setFirstNames,
      setLastNames,
      group,
    ],
  )

  return (
    <Form onSubmit={handleSubmit}>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      {group.flatMap((guest) => {
        return [
          <GuestForm
            key={guest.id}
            guest={guest}
            handleRemoveGuest={handleRemoveGuest}
            setFirstNames={setFirstNames}
            firstNames={firstNames}
            setLastNames={setLastNames}
            lastNames={lastNames}
            attending={attending}
            setAttending={setAttending}
            mealChoices={mealChoices}
            setMealChoices={setMealChoices}
            handleAddGuest={handleAddGuest}
            allergies={allergies}
            setAllergies={setAllergies}
          />,
          ...(additionalGuests[guest.id] ?? []).map((id) => {
            const guest = {
              id,
              firstName: firstNames[id] ?? "",
              lastName: lastNames[id] ?? "",
            }
            return (
              <GuestForm
                key={id}
                guest={guest}
                handleRemoveGuest={handleRemoveGuest}
                setFirstNames={setFirstNames}
                firstNames={firstNames}
                setLastNames={setLastNames}
                lastNames={lastNames}
                attending={attending}
                setAttending={setAttending}
                mealChoices={mealChoices}
                setMealChoices={setMealChoices}
                handleAddGuest={handleAddGuest}
                allergies={allergies}
                setAllergies={setAllergies}
                additionalGuest
              />
            )
          }),
          guest.additionalAllowed &&
            (additionalGuests[guest.id]?.length ?? 0) <
              guest.additionalAllowed && (
              <Button appearance="secondary" type="button" onClick={() => handleAddGuest(guest.id)}>
                Add a guest
              </Button>
            ),
        ]
      })}
      <Label>
        Leave a message{" "}
        <Input
          type="checkbox"
          checked={enableLeaveMessage}
          onChange={() => setEnableLeaveMessage(!enableLeaveMessage)}
        />
      </Label>
      {enableLeaveMessage && (
        <TextArea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={8}
        />
      )}
      <Button>RSVP</Button>
    </Form>
  )
}
