import { Link } from "gatsby"
import React from "react"
import Heading from "../../components/Heading"
import Paragraph from "../../components/Paragraph"

export const ThankYouScreen = ({ isAttending }: { isAttending?: boolean }) => {
  return (
    <>
      <Heading level="1">
        {isAttending ? `Thank You!` : "All done, thank you!"}
      </Heading>
      {isAttending && (
        <>
          <Paragraph>
            We look forward to seeing you at the wedding! Check out our{" "}
            <Link to="/travel">Travel</Link> pages to help you plan your trip,
            and make sure you understand our <Link to="/covid">COVID</Link>{" "}
            policies!
          </Paragraph>

          <Paragraph>
            Those travelling from overseas are reminded to check the visa and
            covid requirements for entering the United States and returning
            home.
          </Paragraph>
        </>
      )}
    </>
  )
}
