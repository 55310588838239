import classNames from "classnames"
import React, { FormHTMLAttributes, DetailedHTMLProps } from "react"
import * as styles from "./Form.module.css"

export const Form = (
  props: DetailedHTMLProps<
    FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >,
) => <form {...props} className={classNames(styles.form, props.className)} />
